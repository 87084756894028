import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"

// import { Link, useStaticQuery, graphql } from "gatsby"
// import Container from "react-bootstrap/Container"
// import Colonne from "react-bootstrap/Col"
// import Row from "react-bootstrap/Row"
// import Button from "react-bootstrap/Button"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import VideoModal from "./VideoModal"
import useGetVideoQuery from "../queries/videoQueries"
import "./presentation.scss"

const motiondesign = (
  <StaticImage
    src="../images/mac.png"
    width={300}
    quality={95}
    className="picto"
    formats={["auto", "webp", "avif"]}
    alt="logo motiondesign"
    style={{ marginBottom: `0rem` }}
  />
)
const itw = (
  <StaticImage
    src="../images/camera.png"
    width={300}
    quality={95}
    className="picto"
    formats={["auto", "webp", "avif"]}
    alt="logo motiondesign"
    style={{ marginBottom: `0rem` }}
  />
)
const drone = (
  <StaticImage
    src="../images/drone2.png"
    width={300}
    quality={95}
    className="picto"
    formats={["auto", "webp", "avif"]}
    alt="logo motiondesign"
    style={{ marginBottom: `0rem` }}
  />
)


const textElements = [
  [
  "Création audiovisuelle",
  'Vous avez une idée originale de film en tête ? Nous vous accompagnons et mettons à votre disposition tout notre savoir-faire.',
  "creationsaudiovisuelles",
  ],
  [
  "Interviews",
  "Des interviews passionnantes pour mettre en valeur votre projet et fédérer autour d’une personnalité.",
  "interviews",
  ],
  [
  "Communication digitale",
  "Des vidéos courtes et marquantes pour vos campagnes marketing.",
  "communicationdigitale",
  ],
  [
  "Motion design",
  "Du motion design créatif et inspirant pour toucher votre audience et faire passer votre message.",
  "motiondesign",

  ],
  [
  "Prises de vue Drone",
  "Donnez de la hauteur à vos productions grace à notre flotte de drone et nos pilotes certifiés pour des prises de vue à couper le souffle !",
  "drone",
],
  [
  'Captation événementielle',
  "Partagez les meilleurs moments de vos événements avec nos captations live et nos aftermovies.",
  'captation',
  ],
  
  ]


const BlockPresentation = props => {

  let {image, text, titre} = props
  if (!image) {image = ""}
  if (!text) {text = "texte"}
  if (!titre) {titre = "titre"}
  const gastbyImage = getImage(image)
  // imageSource = "../images/logo_white_2.png"
  return (
    <div className="block">
      <div className="image">
      <GatsbyImage image={gastbyImage} alt={titre} />

      </div>
      <div className="titre">
        {titre}
      </div>
      <div className="delimitation">
        
      </div>

      <div className="text">
        {text}
      </div>
    </div>
  )
}
const Presentation = () => {
  const imagesQueries = useStaticQuery(graphql`query MyQuery {
    allImageSharp {
      
      nodes {
        id
        parent {
          id
          ... on File {
            id
            name
            extension
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }`)
  let images = imagesQueries.allImageSharp.nodes;
  images = images.filter((image) => image.parent.relativePath.startsWith('presentation')).sort((a,b)=>{return a.parent.name.localeCompare(b.parent.name)}).map((value)=>value.parent.childImageSharp);
  return (
    <div>
                    {/* <div className="logo">{motiondesign}</div> */}


    <div className="titre"><b>PICARO</b>, C'EST UN SAVOIR-FAIRE COMPLET <br/> AU SERVICE DE VOTRE COMMUNICATION</div> 

    <div className="pictos">
    <div className="pictoUnique">{itw}<div className="pictoName">TOURNAGE</div></div>
    <div className="pictoUnique">{drone}<div className="pictoName">DRONE</div> </div>
    <div className="pictoUnique">{motiondesign}<div className="pictoName">MOTION DESIGN</div> </div>

    </div>

         

    {/* <div className="blockWrapper2">
      <div className="blockWrapper">
      {textElements.map((el, index) => {
        return  <div key={index} className="blockPresentationWrapper">
                  <Link
         className=""
          to={"/about/" + el[2]}
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >
                  <BlockPresentation  titre={el[0]} imageSource={"../images/bonnesmines.png"} text={el[1]} image={images[index]}></BlockPresentation>
                  </Link>
               
                </div>
      })}

      </div>
    </div> */}
    </div>
  )
}

Presentation.propTypes = {
  aProp: PropTypes.string,
}

Presentation.defaultProps = {
  aProp: ``,
}

export default Presentation
